import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Advantages of SIMTRIM" />
    <h1>Advantages of Using the SIMTRIM System</h1>
    <p>Using the SIMTRIM™ Molding System results in the following:</p>
    <ul>
      <li>Molding or trim is easy to install, remove and re-install.</li>
      <li>With no molding or trim in the way it is ideal for painting of walls, wall papering or any other wall covering operation.</li>
      <li>No masking is required when painting because the molding or trim is out of the way while painting, and this results with no mess on molding or trim when painting walls.</li>
      <li>No nails, brads or staples penetrate the front face of molding or trim, giving the trim a fresh clean look at all times.</li>
      <li>Improves and speeds up flooring changes.</li>
      <li>Allows molding or trim to be totally pre-finished before being installed.</li>
      <li>Allows wood or mdf molding or trim to be used extensively with metal stud backing (now found in nearly all commercial and industrial building applications).</li>
      <li>When molding and trim is removable and replaceable all painting jobs will become faster and the quality of work will be dramatically improved. Baseboards, casings, wainscoting and crown molding can be refinished at any time. You can even paint or apply any type of covering over your removable trim because there will not be any nails, brads or staples penetrating the front face.</li>
      <li>Significant lifetime cost savings when compared to existing methods of installing molding and trim.</li>
      <li>The amount of molding or trim that will be thrown away during home renovations will be dramatically less than what occurs now with non-removable molding and trim systems.</li>
      <li>The SIMTRIM™ Molding System is the environmental solution, because it can significantly reduce the amount of molding or trim that currently ends up in landfills because it is always damaged when building renovations are done</li>
    </ul>
  </Layout>
)

export default SecondPage
